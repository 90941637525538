.sidebar-container{
    display: flex;
    flex-direction: column;
    background-color: #0b113b;
   
    min-height: 500px;
    padding: 32px 16px;
    justify-content: space-between;
   

}
.sidebar-container > div:nth-child(1)  {
    /* Your custom styles */
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    
}
.sidebar-container > div:nth-child(2)  {
    /* Your custom styles */
    display: flex;
    flex-direction: column;
    align-items: center;
    
}
.sidebar-container-expanded{
    display: flex;
    flex-direction: column;
    background-color: #0b113b;
    width: 252px;
    font-family: 'Roboto';
    padding: 32px 16px;
    position:absolute;
    left: 72px;
    top: 0;
    bottom: 0;
    z-index: 100000000000000000000;
    color: #ffffff;
    gap: 5px;
    border-left:2px solid rgb(255, 255, 255,0.2);
    overflow-y: scroll;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    
}
.sidebar-container-expanded > h3:nth-child(1){
    color: #ffffff;
}
.sidebar-container-expanded li {
    list-style-type: none;
    /* margin-bottom: 1.5rem;
    margin-left: 1rem; */
    /* opacity: 0.6; */
   
}
.navlink{
    text-decoration: none;
    outline: none;
    color: #ffffff;
display: flex;
align-items: center;
padding: 10px;
opacity: 0.6;

}
.navlink-active{
    background-color: rgb(255, 255, 255,0.4);
    border-radius: 3px; 
}
.navlink:hover {
    background-color: rgb(255, 255, 255,0.4);
    border-radius: 3px;
}