.dashed-box {
    
    height: 70vh;
    width: 90%;
    border-radius: var(--Radius-XS, 4px);
    border: 1px dashed var(--boarders-lines-neutral-subtle, #D0D0D0);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    /* margin: 1.5rem 3rem 0rem 1.5rem; */
    padding: 10px;
    background-color: #f9f9f9;
    flex-direction: column;
    gap: 3rem;
    
  }
.dashed-box-child{
    display: flex;
    flex-direction: column;
 
}
.dashed-box-child > div:nth-child(1) {
    margin-bottom: 1.5rem; 
}
.dashed-box-child > div:nth-child(2) {
    margin-bottom: 0.5rem; /* 0.5rem gap after the second div */
}
.dashed-box-button{
  border-radius: var(--Radius-XS, 4px);
  background: var(--Buttons-Secondary-default, #1890FF);
border: none;
  display: flex;
  padding: var(--XS-4XS, 10px) var(--S-3S, 16px);
  justify-content: flex-end;
  align-items: center;
  gap: var(--XS-3XS, 8px);
  color: #fff;
}

.noData-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 25vh;
  gap: 0.5rem;
}
.noData-container > div:nth-child(2)
{color: var(--Texts-title_neutral, #1F1F1F);
text-align: center;

/* Body/Body 01/18 - Semi Bold */
font-family: Figtree;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.noData-container > div:nth-child(3)
{
  color: var(--Texts-body, #4D4D4D);
  text-align: center;
  
  /* Body/Body 03/14 - Regular */
  font-family: Figtree;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

  /* Style for the form container (modal) */
.req-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Dark overlay background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Ensure the modal is on top */
   
  }
  
  .req-modal-content {
    background-color: #fff;
    padding: 40px;
    border-radius: 10px;
    width: 90vh;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    position: relative;
    height: 100%;
    max-height: 100vh;
    
    
  }

  .req-modal-content > div:nth-child(1) {
    margin-top: 0;
    color: var(--Texts-title_neutral, #1F1F1F);

    /* Title/20 - Bold */
    font-family: Figtree;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  
  .req-modal-content > div:nth-child(1)>div:nth-child(1)>div:nth-child(1) {
    margin-top: 0;
    color: var(--Texts-title_neutral, #1F1F1F);

    /* Title/20 - Bold */
    font-family: Figtree;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .req-modal-content > div:nth-child(1)>div:nth-child(1)>div:nth-child(2){
    color: var(--Texts-body, #4D4D4D);

    /* Body/Body 03/14 - Regular */
    font-family: Figtree;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  
  
  .req-modal-content input[type="text"]
  {
    /* width: 100%; */
    /* padding: 10px; */
    /* margin: 10px 0; */
    border: 1px solid #ccc;
    border-radius: 5px;
  }

    /* Style for the form container responder (modal) */
.req-modal-overlay-responder {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure the modal is on top */
 
}

.req-modal-content-responder {
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
  width: 550px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  position: relative;
  height: 580px;
  max-height: fit-content;
  overflow-y: auto; /* Enable scroll for smaller viewports */
}

/* Media query for screens with height greater than 815px */
@media (min-height: 815px) {
  .req-modal-content-responder {
      height: 580px; /* Fixed height for larger screens */
      overflow-y: hidden; /* Disable scroll */
  }
}


.req-modal-content-responder > div:nth-child(1)>div:nth-child(1) >div:nth-child(1){
  margin-top: 0;
  color: var(--Texts-title_neutral, #1F1F1F);

  /* Title/20 - Bold */
  font-family: Figtree;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.req-modal-content-responder > div:nth-child(1)>div:nth-child(1)>div:nth-child(2){
  color: var(--Texts-body, #4D4D4D);

  /* Body/Body 03/14 - Regular */
  font-family: Figtree;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}


.req-modal-content-responder input[type="text"]
{
  /* width: 100%; */
  /* padding: 10px; */
  /* margin: 10px 0; */
  border: 1px solid #ccc;
  border-radius: 5px;
}
  

 
  #showFormBtn {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: block;
    margin: 20px auto;
  }
  
  #showFormBtn:hover {
    background-color: #0056b3;
  }


  .req-modal-form {

    margin-top: 2rem;
    background-color: #fff;
    color: #fff;
    max-height: 100vh;
    height: 80%;
    overflow-y: scroll;
    padding: 2px;
    scrollbar-width: none;
}
.req-modal-form::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.req-modal-form-responder {

  margin-top: 2rem;
  background-color: #fff;
  color: #fff;
  max-height: 80vh;
  /* height: 50vh; */
  overflow-y: scroll;
  padding: 2px;
  scrollbar-width: none;
}
.req-modal-form-responder::-webkit-scrollbar {
width: 0;
height: 0;
}

.req-form-subtitle{
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-bottom: 1.5rem;
}
.form-title {

    color: var(--Texts-title_neutral, #1F1F1F);


    font-family: Figtree;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: left;

   
}

.form-group{
    display: flex;
    /* flex-direction: row; */
    gap: 1rem;
    margin-bottom: 1rem;
}
.form-group-responder{
  display: flex;
  /* flex-direction: row; */
  gap: 1rem;
  margin-bottom: 1rem;
}

.input-group{
    
   
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 50%;
    
   
}

.input-group label {
    font-size: 0.875rem;
    font-weight: bold;
    margin-bottom: 0.25rem;
    color: #7e7e7e;
    
}

.input-group input {
    padding: 0.5rem;
 
    
    border: 1px solid #DADADA;
    border-radius: 4px;
    color: #000;
    background-color: #fff;
   

/* Body/Body 03/14 - Regular */
  font-family: Figtree;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
 
}
/* .input-group Select{
  padding: 0.5rem;
 
    
  border: 1px solid #DADADA;
  border-radius: 4px;
  color: #000;
  background-color: #fff;
 

font-family: Figtree;
font-size: 8px;
font-style: normal;
font-weight: 400;
line-height: normal;
} */

#searchable-select{
  /* padding: 0.5rem; */
  /* border: 1px solid #DADADA; */
  border-radius: 4px;
  color: #000;
  background-color: #fff;
font-family: Figtree;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.input-group input::placeholder{
  color: #dadada;
  line-height: normal;
  text-align: start;
  
}
.input-group input:focus{
  border: 1px solid var(--Surface-Primary-subtle, #1089FF);
outline: none;
}
.input-group-responder{
    
   
  display: flex;
  flex-direction: column;
  flex: 1;
  
 
}

.input-group-responder label {
  font-size: 0.875rem;
  font-weight: bold;
  margin-bottom: 0.25rem;
  color: #7e7e7e;
  
}

.input-group-responder input {
  padding: 0.5rem;

  
  border: 1px solid #DADADA;
  border-radius: 4px;
  color: #000;
  background-color: #fff;
 

/* Body/Body 03/14 - Regular */
font-family: Figtree;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;

}
.input-group-responder Select{
padding: 0.5rem;

  
border: 1px solid #DADADA;
border-radius: 4px;
color: #000;
background-color: #fff;


/* Body/Body 03/14 - Regular */
font-family: Figtree;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.input-group-responder input::placeholder{
color: #dadada;
line-height: normal;
text-align: start;

}
.input-group-responder input:focus{
border: 1px solid var(--Surface-Primary-subtle, #1089FF);
outline: none;
}
/* 
.description input::placeholder{
  color: #000;
  line-height: 1.5;
  transform: translateY(-30%);
} */

#searchable-select::placeholder{
  font-size: 12px;
}
.draft-button{
  padding: 0.75rem 1.5rem;

color:#1890FF ;
  background-color: #fff;
  
  cursor: pointer;
  border-radius: var(--Radius-XS, 4px);
  border: 1px solid var(--Buttons-Secondary-default, #1890FF);
  height: 2.5rem;
}

.draft-button:hover {
  color: #0056b3;
  border: 1px solid var(--Buttons-Secondary-default, #0056b3);
}

.submit-button {
    padding: 0.75rem 1.5rem;
  
    color: #fff;
    background-color: #1890FF;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    height: 2.5rem;
}

.submit-button:hover {
    background-color: #0056b3;
}
.submit-button-responder{
  

  color: #fff;
  background-color: #1890FF;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  height: 2.25rem;
  width: 5rem;
}

.submit-button-responder:hover {
  background-color: #0056b3;
}

.all-buttons{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  align-items: center;
  bottom: 0rem;
  background-color: #fff;

  padding-top: var(--S-4S, 20px);
  width: 90%;
height: 75px;
padding-right: 20px;
  align-self: stretch;

}
.all-buttons-responder{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  /* position: absolute; */
  align-items: center;
  bottom: 2rem;
  background-color: #fff;
  right: 40px;
  padding-top: var(--S-4S, 20px);
  width: 100%;
gap: 1rem;
  align-self: stretch;

}
.all-buttons >div:nth-child(2){
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
}
.close-btn{
  height: 2.5rem;
  width: 5rem;
  
  
  color:#4D4D4D ;
  background-color: #fff;
  
  cursor: pointer;
  border-radius: var(--Radius-XS, 4px);
  border: 1px solid var(--Buttons-Neutral-default, #4D4D4D);
}
.close-btn:hover{
  border-radius: var(--Radius-XS, 4px);
  border: 1px solid var(--Buttons-Neutral-default, #000);
}
.close-btn-responder{
  height: 2.25rem;
  width: 5rem;
  
  
  color:#4D4D4D ;
  background-color: #fff;
  
  cursor: pointer;
  border-radius: var(--Radius-XS, 4px);
  border: 1px solid var(--Buttons-Neutral-default, #4D4D4D);
}
.close-btn-responde:hover{
  border-radius: var(--Radius-XS, 4px);
  border: 1px solid var(--Buttons-Neutral-default, #000);
}
  
  
.requests-table-container{
  background-color: #F5F5F5;
  width: 100%;
  height: 100%;
  padding:20px ;
  gap: 1.5rem;
  display: flex;
  flex-direction: row;
  min-width: fit-content;
  margin-bottom: 30px;

}
.requests-table-subcontainer{
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.table-subcontainer{
  min-width: 876px;


}
.pagenation-container{
  text-align: right;
}

.editModal-container{
  background-color: #fff;
  /* height: 76px; */
  width: 154px;
  display: flex;
  padding: var(--XS-XS, 4px) var(--XXX-XXS, 2px);
  flex-direction: column;
  align-items: center;
  gap: var(--XXX-XXS, 2px);
  z-index: 2000;
  position: fixed;
  border-radius: var(--Radius-XS, 4px);
  background: var(--Backgrounds-white, #FFF);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
  
}
.editModal-container >div:nth-child(1){
  display: flex;
/* height: 33px; */
padding: var(--XS-3XS, 8px) var(--S-3S, 16px);
/* align-items: flex-start; */
gap: var(--XS-3XS, 8px);
align-self: stretch;
color: #1F1F1F;
}
.editModal-container > div:nth-child(2){
  display: flex;
/* height: 33px; */
padding: var(--XS-3XS, 8px) var(--S-3S, 16px);
align-items: flex-start;
gap: var(--XS-3XS, 8px);
align-self: stretch;
color: #F5222D;
}
.hover-item {
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.hover-item:hover {
  background-color: #F5F5F5;
}
.requests-datepicker{
  display: flex;
/* height: 38px; */
flex-direction: column;
align-items: flex-start;
align-self: stretch;
border-radius: var(--Radius-XS, 4px);

}
.approver-select{
  width: 50%;
}
.css-1nmdiq5-menu > div{
  max-height: 100px !important;

}
.ant-picker-dropdown{
  z-index: 10000000 !important;  /* Set z-index to a high value */
}
.error-border {
  border: 1px solid #FF4D4F !important;
  border-radius: 4px;
}
.css-hlgwow {
text-overflow: ellipsis;
white-space: nowrap;
}
.denialModal .ant-modal-footer{
  margin-top: 40px !important;
}

.denialModal .ant-modal-content{
  width: 456px !important;
}

.confirmationModal .ant-modal-content{
  width: 416px !important;
  
}
