.custom-styled-arrows{
    border: none !important;
    background-color: #FFF !important;
    cursor: pointer;
}
.ant-table-wrapper .ant-table-thead >tr>th{
    background-color: #FFF !important;
}
.ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-content >table >thead>tr>th{
    border-width: 3px !important; 
}
.card-header>div:nth-child(1){
    background-color: black;
}