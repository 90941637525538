
.testpaper-wrapper{
    display: flex;
    flex-direction: column;
    height: 100%;
    position:absolute;
    top:40px;
    width: 100%;
    overflow:auto;
    background-color: #F5F5F5;
    }
    
    .testpaper-container{
   
        display:flex;
        flex-direction:row;
        justify-content:space-between;
        align-items:center;
        padding:20px;
        background-color:white;
        margin:5px 5px 0px 5px;
        border-top-left-radius:3px;
        border-top-right-radius: 3px;
       
    }