.custom-tab:hover{
  background-color: #F5F5F5 !important;
}


.tabs-wrapper::-webkit-scrollbar{
  display: none;
}
.tabs-container{
  display: flex;
  flex-direction:column;
  padding:0px 20px;
  background-color:white;
  margin:0px 5px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}