.pipelineview-container-header{
    display: flex;
    width: 100%;
    padding: var(--M-M, 24px) var(--L-3L, 48px) var(--M-M, 24px) var(--M-M, 24px);
    /* align-items: flex-start; */
    gap: var(--M-M, 24px);
    background-color: #fff;
    height: 95px;
   justify-content: space-between;
}

.pipelineview-container-header> div:nth-child(1)> div:nth-child(2)>div:nth-child(1){
    color: var(--Texts-title_neutral, #1F1F1F);
font-family: Figtree;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.pipelineview-container-header>div:nth-child(1)>div:nth-child(3){
    color: var(--Texts-captions, #7A7A7A);

/* Body/Body 03/14 - Regular */
font-family: Figtree;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;

}
.pipelineview-subcontainer{
  
    background-color: #F5F5F5;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 24px;
}
.pipelineview-subcontainer>div:nth-child(2){
    display: flex;
    gap: 24px;
}
.pipelineview-panel{
    display: flex;
    flex-direction: row;
    padding: 24px;
    padding-top: 0px;
    overflow-x: scroll;
    gap: 24px;
    height: 600px;
    overflow-y: scroll;
}

.breadcrumb{
    list-style: none;
    padding-left: 0;
    margin-top: 0;
    margin-left: 8px;
}
.breadcrumb li{
    display: inline;
    color: #7A7A7A;
}
