.calendar-page-container{
    margin-top: 40px;
    color: #000;
    overflow: scroll;
}
.calendar-page-container>div:nth-child(1){
    background-color: white;
    height: 96px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap:8px;
}
.calendar-page-container>div:nth-child(1)>div:nth-child(1){
    color: var(--Texts-title_neutral, #1F1F1F);
    font-family: Figtree;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.calendar-page-container>div:nth-child(2){
    display: flex;
    flex-direction: row;
    margin: 24px;
    justify-content: space-between;
}
.calendar-page-container>div:nth-child(2)>div:nth-child(2){
    display: flex;
    gap: 8px;
}

.calendar-page-container>div:nth-child(1)>div:nth-child(2){
    color: var(--Texts-body, #4D4D4D);

/* Body/Body 03/14 - Regular */
font-family: Figtree;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}