.container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }
  
  .stepWrapper {
    display: flex;
    flex-direction: column;
  }
  
  .stepBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
    background: #ffffff;
    border-radius: 8px;
    /* box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1); */
    transition: 0.3s;
    cursor: pointer;
    justify-content: space-between;
  }
  
  .stepBox:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .stepNumber {
    width: 30px;
    height: 30px;
    /* border-radius: 50%; */
    color:#e0e0e0;
    border: 1px solid #e0e0e0;
    /* background-color: #e0e0e0; */
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    margin-right: 15px;
  }
  
  .stepText {
    font-size: 16px;
    font-weight: 500;
  }
  
  /* Expandable Content */
  .expandedContent {
    margin-left: 45px;
    padding: 10px;
    background: #f9f9f9;
    border-left: 4px solid #1890ff;
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
  }
  /* .container {
    width: 100%;
    max-width: 500px;
    margin: auto;
    font-family: Arial, sans-serif;
  } */
  
  /* Header Section */
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    padding: 8px 8px;
    border-radius: 8px;
    /* border: 1px solid #ddd; */
    cursor: pointer;
    transition: background 0.3s ease-in-out;
  }
  
  .header:hover {
    background: #f5f5f5;
  }
  
  .headerLeft {
    display: flex;
    align-items: center;
  }
  
  
  
  .title {
    font-size: 16px;
    font-weight: 600;
  }
  
  .toggleIcon {
    color: #666;
    font-size: 14px;
  }
  
  /* Dropdown Content */
  .dropdownContent {
    margin-top: 10px;
    padding: 12px 16px;
    /* border: 1px solid #ddd; */
    border-radius: 8px;
    background: white;
    /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); */
  }
  
  /* Progress Section */
  .progressSection h3 {
    font-size: 14px;
    margin-bottom: 6px;
    color: #333;
  }
  
  .progressBar {
    width: 100%;
    height: 24px;
    background: #e0e0e0;
    border-radius: 1px;
    overflow: hidden;
  }
  
  .progressFill {
    height: 100%;
    background: #8a5af1;
  }
  
  .progressText {
    font-size: 12px;
    color: #555;
    margin-top: 4px;
  }
  
  /* Info Section */
  .infoSection h3 {
    font-size: 14px;
    margin-top: 12px;
    margin-bottom: 6px;
  }
  
  .infoSection p {
    font-size: 13px;
    color: #555;
    margin: 4px 0;
  }
  
  /* Checkbox Section */
  .checkboxSection {
    display: flex;
    align-items: center;
    margin-top: 12px;
  }
  
  .checkboxSection input {
    margin-right: 8px;
  }
  
  .analysisBox {
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #ddd;
    /* background: #fafafa; */
    margin-bottom: 16px;
  }
  .dashedBox {
    border: 2px dashed #b0a9a9; /* Black dashed border */
    padding: 20px; /* Inner spacing */
    width: 100%; /* Adjust as needed */
    /* text-align: center;  */
  }
  .matchSection {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
  }
  
  .badge {
    /* background: #f4f4ff; */
   
    font-weight: bold;
    padding: 4px 10px;
    border-radius: 4px;
  }
  
  .description {
    font-size: 14px;
    text-align:left;
    color: #4D4D4D;
    margin-top: 8px;
  }
  
  /* Score Meter */
  .scoreSection {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin: 12px 0;
  }
  
  .scoreMeter {
    width: 100px;
    height: 10px;
    background: #e0e0e0;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .meterFill {
    height: 100%;
    background: #ff9800;
  }
  
  .scoreText {
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
  
  /* Skill Metrics */
  .skillMetrics {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 12px;
  }
  
  .metric {
    font-size: 14px;
    color: #555;
    width: 20%;
    margin-bottom: 4px;
  }
  .resumeScreenBtn{
 
    color:white;
    
    height:30px;
    border-radius:5px;
    width:80px
  }
  /* Candidate Count */
  .candidateCount {
    font-size: 13px;
    color: #555;
    margin-top: 12px;
    text-align: center;
  }
  
  .highlight {
    color: #007bff;
    font-weight: bold;
  }
  
  /* Action Section */
  .actionSection {
    text-align: center;
  }
  
  .actionText {
    font-size: 14px;
    margin-bottom: 12px;
    color: #555;
  }
  
  .rejectButton, .shortlistButton {
    padding: 8px 16px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 4px;
    cursor: pointer;
    border: none;
  }
  
  .rejectButton {
    background: #ffebee;
    color: #d32f2f;
    margin-right: 8px;
  }
  
  .rejectButton:hover {
    background: #ffcdd2;
  }
  
  .shortlistButton {
    background: #e8f5e9;
    color: #2e7d32;
  }
  
  .shortlistButton:hover {
    background: #c8e6c9;
  }
  .section {
    margin-bottom: 16px;
  }
  
  .heading {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
  }
  
  .checkboxGroup {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .availabilityInputs {
    display: flex;
    gap: 12px;
  }
  
  .advanceButton {
    width: 100%;
    margin-top: 16px;
  }
  .emptyState {
    text-align: center;
    margin-bottom: 16px;
  }
  
  .buttonGroup {
    display: flex;
    justify-content: center;
    gap: 12px;
    margin-bottom: 16px;
  }
  
  .addButton {
    background: #f5f5f5;
    border: 1px solid #d9d9d9;
    color: #8c8c8c;
  }
  
  .nextStage {
    padding: 16px;
    background: #f9f9f9;
    border-radius: 8px;
    text-align: center;
  }
  
  .nextStageText {
    font-size: 14px;
    margin-bottom: 12px;
  }
  
  .actionButtons {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
  }
  .details {
    margin-top: 12px;
  }
  
  .agenda {
    margin-top: 16px;
  }
  
  .attachments {
    margin-top: 16px;
  }
  
  .attachmentImages {
    display: flex;
    gap: 12px;
    margin-top: 8px;
  }
  
  .attachmentImages img {
    width: 100px;
    height: 100px;
    border-radius: 4px;
    border: 1px solid #ddd;
  }
  .statusRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 12px;
  }
  
  .feedbackRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .nextSteps {
    font-size: 14px;
  }
  
  .actions {
    display: flex;
    gap: 10px;
    margin-top: 12px;
    justify-content: flex-end;
  }
  .statusRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 12px;
  }
  
  .detailsJob {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
    border-top: 1px solid #ddd;
  }
  
  .template {
    display: flex;
    flex-direction: column;
  }
  
  .templateTitle {
    font-weight: bold;
  }
  
  .templateDesc {
    color: gray;
    font-size: 14px;
  }
  
  .footer {
    margin-top: 12px;
    font-size: 14px;
    color: gray;
  }
 
  