.job-application-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 24px;
    gap: 20px;
}
.resume-section,.skill-section,.education-section,.experience-section,.headline-section{
    width: 100%;
    height: max-content;

}


.drag-upload .ant-upload-btn{
    background-color: #ffffff !important;
   }
.resume-container{
    height: 170px !important;
    border-radius: 8px;
    border: 1px solid #E9E9E9;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}
.resume-meta{
    display: flex;
    flex-direction:column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;
}

.resume-data,.resume-actions{
    display: flex;
    flex-direction: row;
   
    align-items: center;
}
.resume-data{
    justify-content: flex-start;
gap: 30px;
width: 60%;
}
.resume-meta h3{
    margin: 2px 0px;
}
.resume-actions{
justify-content: flex-end;
gap: 30px;
flex-grow: 1;
}
.resume-name {
    width: 100%;
    cursor: text;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; /* Limits text to 2 lines */
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word; /* Ensures words wrap properly */
}

.view-resume{
    color: #1890FF;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}

 .drag-upload .ant-upload-btn{
    height: 170px !important;
   }

.cancel-upload{
    color: #F5222D;
    font-weight: 500;
    font-size: 14px;
    border:1px solid #F5222D;
    background-color: #ffffff;
    border-radius: 3px;
    cursor: pointer;
}

   .drag-upload  .ant-upload-list-item-name{
    color: #1890FF;
    font-weight: 500;
    font-size: 14px;

   }
   .drag-upload .ant-upload-icon svg{
    fill:#4D4D4D
   }
   .drag-upload  .ant-upload-list-item-actions button{
    opacity: 1 !important;

   }
   .drag-upload  .ant-upload-list-item-actions button svg{
    fill:#F5222D;

   }
   .drag-upload .ant-upload-list-item{
    cursor: pointer;
   }



.add-more{

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    font-size:14px;
    font-weight: 600;
    width: max-content;
    color: #1890FF;
  margin: 0 auto;
}
.pdf-document, .pdf-document div{
    min-height:90vh;
    min-width: 40vw;
    display: flex;
    justify-content: center;
    align-items: center;
}
.resume-view-modal .ant-modal-content{
    top: -20px;
    width: 100%;

}
.resume-view-modal  .ant-modal-content{
    background-color: transparent !important;
    box-shadow: none !important;
}
.resume-view-modal .ant-modal-header{
    background: transparent !important;
   
}
.resume-view-modal .ant-modal-header  p{
    color: #ffffff !important;
}
.resume-view-modal .ant-modal-close{
    background: #4D4D4D;
    border-radius: 100px !important;
    color: #ffffff;
    top: 40px;
  

}


.resume-page-meta{
     display: flex;
     flex-direction: row;
     justify-content: space-between;
     align-items: center;
     width: 180px;
     height: 50px;
     margin: 0 auto;
     background-color: #fff;
     border-radius: 3px;
  color: #000;
  margin-top: 10px;

}
.resume-page-meta button{
    outline: none;
    background: none;
    border: none;
    font-size: 23px;
    cursor: pointer;
    height: 100%;
    background: none !important;
    border-radius: 0;
  


}
.resume-page-meta .next-btn:hover{
    
    background: #d9d9d9  !important;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}
.resume-page-meta .prev-btn:hover{
    
    background: #d9d9d9  !important;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}
.resume-page-meta button svg path{
    fill: #000;
}

.reference-cards,.workexperience-cards{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.reference-card,.workexperience-card{
    display: flex;
    flex-direction: row;
    gap: 30px;

}
.referenc-profile{
    display: flex;
    align-items: baseline;

}
.workexperience-profile{
    display: flex;
    align-items: baseline;
    margin-top: 2px;
}
.referenc-header,.workexperience-header{
    display: flex;
    flex-direction:column;
    justify-content: center;
    gap: 20px;
    margin-top: 5px;
}
.reference-title h3,.workexperience-title h3{
    margin: 0 2px;
    color: #1F1F1F;
    font-size: 16px;
    font-weight: 600;
}
.reference-title span{
    color: #7A7A7A;
    font-size: 14px;
    font-weight: 500;
}
.reference-contact{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}
.reference-contact span{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}
.workexperience-title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
}
.block-dot{
    width: 6px;
    height: 6px;
    border-radius: 100px;
    background: #4D4D4D;
}
.workexperience-title div{
    display: flex;
    flex-direction: row;
  align-items: center;
    gap: 10px;
    color: #4D4D4D;
    font-size: 14px;
    font-weight: 500;

}

.workexperience-profile div{

    height: 48px;
    width: 48px;
    border-radius: 12px;
    background-color: #E6FFFB;
    display: flex;
    justify-content: center;
    align-items: center;
}

.delete-resume{
    color: #F5222D;
    
}
.delete-resume svg path {
    fill: #F5222D;
}

.styled-checkbox{
position: absolute;
right: 0;
top: -5px;

}
.large-text-container{
    position: relative;
    display: inline-block;
    max-width: 100%; /* Adjust to fit your layout */
    max-height: 200px;
    overflow-y: scroll;
}

.large-text-body {
    display: inline-block;
    max-width: 100%; /* Adjust the width as needed */
    overflow: hidden;
    white-space: nowrap;
    /* text-overflow: ellipsis; */
    vertical-align: top;
    line-height: 1.5;
   position: relative;
    @supports (-webkit-line-clamp: 2) {
      overflow: hidden;
      /* text-overflow: ellipsis; */
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  
  .read-more-label {
    display:inline-block;
    color: #007bff; 
    font-size: 14px;
    cursor: pointer;
    margin-left: 8px;
  }
  
  /* When checkbox is checked, expand the content */
  .read-more-toggle:checked ~ .large-text-body {
    white-space: normal; /* Allow text to wrap */
    overflow: visible;
    text-overflow: unset;
    display: block;
  }

  .read-more-toggle:not(:checked) ~ .read-more-label::after {
    content: 'Read More';
  
  }
  
  .read-more-toggle:checked ~ .read-more-label::after {
    content: 'Read Less';
  }

  .read-more-container {
    display: inline; /* Ensures everything stays inline */
  }
  
  .text-content {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: pre-line;
  }
  
  .read-more {
    color: blue;
    cursor: pointer;
    font-weight: bold;
    display: inline; /* Ensures 'Read More' stays inline */
  }
  
  .read-less {
    color: blue;
    cursor: pointer;
    font-weight: bold;
    display: block;
    margin-top: 5px;
  }
  